import TopNav from "../topnav/TopNav";
import "./Resources.scss";

import LeftArrow from "../../assets/images/left-arrow.png";
import RightArrow from "../../assets/images/right-arrow.png";
import { Card, Button, Modal, CardGroup } from "react-bootstrap";
import { useState } from "react";
import firebase from "../../firebase";
import resourcesJSON from "./resourcesv2.json";
import cs from "../../assets/images/cs.png";
import { motion } from "framer-motion";
import {
  containerVariants,
  headingVariants,
  subheadingVariants,
} from "../../hooks/AnimStyles";
import RellaxWrapper from "react-rellax-wrapper";

function Resources() {
  const [formData, setFormData] = useState({ email: "", name: "" });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const resources2 = resourcesJSON.resources.sort(
    (a: any, b: any) => a.timestamp - b.timestamp
  );
  const resources = resources2.slice(Math.max(resources2.length - 5, 0));

  let totalItem = resources.length;
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPDF, setCurrentPdf] = useState("");
  const [pdfDownloadLink, setPdfDownloadLink] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function submitForm(event: any, pdf: any) {
    setPdfDownloadLink("");
    formData.email = "";
    formData.name = "";
    handleShow();
    setCurrentPdf(pdf);
  }

  function downloadFile(event: any) {
    setPdfDownloadLink("");
    if (formData.email === "" || formData.name === "") {
      return;
    }
    event.preventDefault();
    let db = firebase.firestore();
    let win = window;
    db.collection("resource_download")
      .add({ email: formData.email, name: formData.name })
      .then(() => {
        // a.target = "_blank";
        // a.href = "/case-study/pdf/" + currentPDF;
        // a.click();
        //window.location.href = "/case-study/pdf/" + currentPDF;
        setPdfDownloadLink("/case-study/pdf/" + currentPDF);

        win.open("/case-study/pdf/" + currentPDF, "_blank");
        //handleClose();
      })
      .catch((err: any) => {
        alert("Sorry we couldn't talk with our server.");
      });
  }

  function next() {
    let curr = currentIndex;
    curr++;
    if (curr === totalItem) {
      curr = 0;
    }
    setCurrentIndex(curr);
  }

  function prev() {
    let curr = currentIndex;
    curr--;
    if (curr <= -1) {
      curr = totalItem - 1;
    }
    setCurrentIndex(curr);
  }

  function viewCaseStudy(index: any) {
    setCurrentIndex(index);
    window.scrollTo({
      top: 500,
      left: 100,
      behavior: "smooth",
    });
  }

  return (
    <section className="resources">
      <motion.section
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="resources-container"
      >
        <TopNav />
        <div className="page-banner">
          <RellaxWrapper speed={5}>
            <motion.h1 variants={headingVariants}>Case Study</motion.h1>

            <motion.h5 variants={subheadingVariants}>
              Get best practices and industry insights to help you get up to
              speed on the transformation journey
            </motion.h5>
          </RellaxWrapper>
        </div>
      </motion.section>
      <section className="about-content p-lg-4 p-md-2">
        <div className="container">
          <div className="row px-lg-3 pt-2 px-md-12  col-sm-12 ">
            <div className="col-12 col-lg-4 col-md-12 col-sm-12  blue-big-text">
              <div className="row">
                <div className="col col-6">
                  <h5 className="case-study  align-items-left ">CASE STUDY</h5>
                </div>

                <div className="case-study col col-6 text-align-right">
                  {currentIndex + 1}{" "}
                  <span className="grey-shade">/ {resources.length}</span>{" "}
                  &nbsp;&nbsp;
                  <span
                    className={
                      "cursor " + (currentIndex === 0 ? "transparent" : "")
                    }
                    onClick={prev}
                  >
                    <img
                      className="img-fluid"
                      alt="Nav left"
                      src={LeftArrow}
                      width="20px"
                    />
                  </span>{" "}
                  &nbsp;&nbsp;{" "}
                  <span
                    className={
                      "cursor " +
                      (currentIndex === totalItem - 1 ? "transparent" : "")
                    }
                    onClick={next}
                  >
                    {" "}
                    <img
                      className="img-fluid"
                      alt="Nav Right"
                      src={RightArrow}
                      width="20px"
                    />
                  </span>
                </div>
              </div>

              {resources
                .filter((item, index) => index === currentIndex)
                .map((filteredItem, idx) => (
                  <div key={idx}>
                    <h4> {filteredItem.title} </h4>
                    <span className="case-study-desc">
                      {filteredItem.desc.substring(0, 100)}...
                    </span>
                  </div>
                ))}
            </div>
            <div className="col-12 col-lg-8 col-md-12  col-sm-12 ">
              {resources
                .filter((item, index) => index === currentIndex)
                .map((filteredItem, idx) => (
                  <div key={idx} className="img-container">
                    <img
                      className="img-fluid"
                      alt={`Resources${idx}`}
                      src={"/case-study/images/banner/" + filteredItem.banner}
                    />
                    <br></br>
                    <p>
                      <br></br>
                      <br></br>
                      {filteredItem.desc}
                    </p>
                    <br></br>

                    <button
                      type="submit"
                      className="btn btn-light"
                      onClick={(e) => submitForm(e, filteredItem.pdf)}
                    >
                      Download PDF
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <section>
          <h3>More case studies</h3>
          <br></br>
          <br></br>
          <div className="row">
            {resources2.map((item, index) => (
              <div className="col-md-4" key={index}>
                <CardGroup>
                  <Card className="mx-auto my-2 img-container">
                    <img
                      className="img-fluid"
                      alt={`Card ${index}`}
                      src={"/case-study/images/thumbnail/" + item.banner}
                    />
                    <Card.Body>
                      <Card.Title>{item.title.substring(0, 25)}...</Card.Title>
                      <Card.Text>{item.desc.substring(0, 80)}</Card.Text>
                    </Card.Body>

                    <div
                      className="viewCaseStudy"
                      onClick={(e) => submitForm(e, item.pdf)}
                    >
                      View Case Study &nbsp;&nbsp;
                      <img
                        alt="Nav Right"
                        className="img-fluid"
                        src={RightArrow}
                        width="20px"
                      />
                    </div>
                  </Card>
                </CardGroup>
              </div>
            ))}
          </div>
        </section>
      </section>
      <section className="resources-content"></section>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="/" className="mb-4 mb-lg-0">
            <div className="form-row">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  required
                  className="form-control"
                  id="name"
                  placeholder="Name*"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                  name="email"
                  id="email"
                  placeholder="Email*"
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="btn btn-light"
                onClick={(e) => downloadFile(e)}
              >
                Download
              </button>
              {pdfDownloadLink && (
                <div>
                  <br></br>If not automatically started the download,{" "}
                  <a
                    className="download-link"
                    target="_blank"
                    href={pdfDownloadLink}
                  >
                    click here to download
                  </a>
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Resources;
