import "./Team.scss";

import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import useOnScreen from "../../hooks/hooks";
import { final, initial } from "../../hooks/AnimStyles";
import exploreLinkImg from "../../assets/images/explore_link.png";
import { Link } from "react-router-dom";
import RellaxWrapper from "react-rellax-wrapper";

function Team() {
  const [animStyle, setAnimStyle] = useState(initial);

  const myRef: any = useRef();
  const isVisible = useOnScreen(myRef);

  useEffect(() => {
    if (isVisible) {
      setAnimStyle(final);
    }
  }, [isVisible]);

  return (
    
    <section>

      <div className="container team px-lg-4 py-5">

        <motion.h5 ref={myRef} animate={animStyle}>
          <div className="home-sub-title">OUR TEAM</div>
        </motion.h5>
        <div className="col-12 col-6-lg">
          <motion.h1 ref={myRef} animate={animStyle}>
            A team of experts.<br></br>
            Best-in-class. Passionate.
          </motion.h1>
          <motion.h5 ref={myRef} animate={animStyle}>
            <div className="row">
              <div className="col-lg-6 col-12 team-of-expert-sub-title">
                We are a global team of experienced specialists with deep
                consulting, engineering & product management expertise. Our
                strong set of values and principles and leading practice
                methodologies, enable us to deliver an unparalleled experience
                to our clients
              </div>
            </div>

            <br></br>

            <div className="row explore-link">
              <Link to="/about">
                <div className="row d-flex align-items-center">
                  <div className="col-3 col-lg-1">
                    <img
                      src={exploreLinkImg}
                      style={{ width: "71px" }}
                      alt="Explore"
                      className="img-fluid d-flex"
                    />
                  </div>
                  <div className="col-9 col-lg-11">
                    {" "}
                    Explore our expertise, our team and our vision.
                  </div>
                </div>
              </Link>
            </div>
          </motion.h5>
          
        </div>
      </div>
      </section>

  );
}

export default Team;
