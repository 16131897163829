import "./Approach.scss";

import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import useOnScreen from "../../hooks/hooks";
import { final, initial } from "../../hooks/AnimStyles";
import RellaxWrapper from "react-rellax-wrapper";

function Approach() {
  const [animStyle1, setAnimStyle1] = useState(initial);
  const [animStyle2, setAnimStyle2] = useState(initial);
  const [animStyle3, setAnimStyle3] = useState(initial);

  const myRef1: any = useRef();
  const myRef2: any = useRef();
  const myRef3: any = useRef();
  const isVisible1 = useOnScreen(myRef1);
  const isVisible2 = useOnScreen(myRef2);
  const isVisible3 = useOnScreen(myRef3);

  useEffect(() => {
    if (isVisible1) {
      setAnimStyle1(final);
    }
    if (isVisible2) {
      setAnimStyle2(final);
    }
    if (isVisible3) {
      setAnimStyle3(final);
    }
  }, [isVisible1, isVisible2, isVisible3]);

  return (
    <section>
      <div id="our-approach"></div>

      <div className="container-lg approach-container py-5">
        <h6 className="text-center">OUR APPROACH</h6>

        <motion.h2
          ref={myRef1}
          animate={animStyle1}
          className="text-center text-primary"
        >
          Our <b>value driven</b> approach is unique
        </motion.h2>
        <div className="row p-lg-4 approach-bg">
          <div className="col">
            <div className="row flex-column">
              <div className="col">
                <motion.h1
                  ref={myRef1}
                  animate={animStyle1}
                  className="text-primary mb-0"
                >
                  ENVISAGE
                </motion.h1>
                <div>
                  We help you envision your ideas into reality, making your
                  digital transformation the foundation of your success.
                </div>
              </div>
              <div className="col advise">
                <motion.h1
                  ref={myRef2}
                  animate={animStyle2}
                  className="text-primary mb-0"
                >
                  ADVISE
                </motion.h1>
                <div>
                  We offer advise you can trust, methods that deliver consistent
                  results and deep expertise you can always count on.
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex right-column">
              <motion.h1
                ref={myRef3}
                animate={animStyle3}
                className="text-primary mb-0"
              >
                SHAPE
              </motion.h1>
              <div>
                We reinforce our clients as leaders in their fields by building
                next gen innovative solutions delivering early and competitive
                advantage.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Approach;
