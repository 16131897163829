import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  containerVariants,
  headingVariants,
  subheadingVariants,
} from "../../hooks/AnimStyles";
import TopNav from "../topnav/TopNav";
import "./Services.scss";
import "rmc-tabs/assets/index.css";
import { Tabs, DefaultTabBar } from "rmc-tabs";
import "rmc-tabs/assets/index.css";
import RellaxWrapper from "react-rellax-wrapper";

function Services() {
  let { services }: any = useParams();
  let [activeMenu, setActiveMenu] = useState("business");
  let clientWidth = document.documentElement.clientWidth;
  useEffect(() => {
    setActiveMenu(moveTo(services));
    window.scrollTo({ top: 500, behavior: "smooth" });
  }, [services]);

  const baseStyle = {
    display: "flex",
  } as React.CSSProperties;

  function moveTo(services: any) {
    let current = "";
    switch (services) {
      case "business":
        current = "business";
        break;
      case "robotic-process-automation":
        current = "hyper";
        break;
      case "ms-power-platform":
        current = "power-platform";
        break;
      case "ui-ux":
        current = "ux";
        break;
      case "cloud-security":
        current = "cloud";
        break;
    }
    return current;
  }

  return (
    <section>
      <motion.section
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="services-container"
      >
        <TopNav />
        <div className="page-banner">
          <RellaxWrapper speed={5}>
            <motion.h1 variants={headingVariants}>
              Complex technology made simple, at a human scale
            </motion.h1>

            <motion.h5 variants={subheadingVariants}>
              From chatbots to hyper automation, we merge the humanities with
              the technologies through creative design to deliver the best
              outcomes for our clients.
            </motion.h5>
          </RellaxWrapper>
        </div>
      </motion.section>
      <section className="services-content" id="all">
        <div style={{ ...baseStyle }}>
          <Tabs
            tabs={[
              { key: "business", title: "Business Transformation" },
              { key: "hyper", title: "Hyper Automation" },
              { key: "power-platform", title: "MS Power Platform" },
              { key: "ux", title: "UX + Design" },
              { key: "cloud", title: "Cloud Security" },
            ]}
            initialPage={"business"}
            page={activeMenu}
            onChange={(tab, index) => {
              setActiveMenu("" + tab.key);
            }}
            renderTabBar={(props) => (
              <DefaultTabBar {...props} page={clientWidth <= 500 ? 3 : 5} />
            )}
          >
            <div key="business">
              <section className="container block p-md-3 p-lg-4 pt-0">
                <div className="row">
                  <div className="col-md-3 left pt-4">
                    <h3>We offer</h3>
                    <ul>
                      <li>Transformation Strategy and Design</li>
                      <li>Program Delivery and Governance</li>
                      <li>Digital Product Development</li>
                      <li>Virtual CIO/CDO/CTO As a Service</li>
                      <li>Virtual Development Centre</li>

                      {/* <li>Digital strategy and governance</li>
                    <li>SaaS product development</li>
                    <li>Cloud security consulting</li>
                    <li>Virtual CIO/CTO services</li>
                    <li>Offshore development and outsourcing</li> */}
                    </ul>
                  </div>
                  <div className="col-md-8 right" id="business">
                    <h3>Business Transformation</h3>

                    <p>
                      We apply proven multidisciplinary methods and tools to
                      address key issues, including aligning business strategies
                      with technology strategies and architectures, managing
                      complex business technology transformations, optimising
                      technology performance/value, and managing technology
                      costs, in the context of appropriately managing risk and
                      achieving business results.
                    </p>
                    <p>
                      We support our clients by helping them use Information
                      Technology (IT) to achieve their business objectives. It
                      is a “client-side” service, based on the provision of
                      independent professional advice, typically to a Chief
                      Information Officer (CIO) or the Technology Leadership
                      Team.
                    </p>
                    <p>
                      Our advisory practice functions independent of vendors,
                      integrators, specific technologies and application
                      packages. We provide independent, unbiased and objective
                      advice which in the best interest of our clients. However,
                      we do maintain close relationships with major technology
                      vendors, such that we understand their capabilities,
                      services and products, and can collaborate with them when
                      this is in our clients best interest.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div key="hyper">
              {" "}
              <section className="container block p-md-3 p-lg-4 pt-0">
                <div className="row">
                  <div className="col-md-3  pr-md-5 left pt-4">
                    <h3>We offer</h3>

                    <ul>
                      <li>Intelligent Automation Consulting</li>
                      <li> Intelligent Document Processing</li>
                      <li> AI & Analytics</li>
                      <li> Computer Vision</li>
                      <li>24 x7 Bot Managed Services</li>
                    </ul>
                  </div>
                  <div
                    className="col-md-8 right"
                    id="robotic-process-automation"
                  >
                    <h3>Hyper Automation</h3>
                    <p>
                      <b>Our hyper automation CoE</b> follows our leading
                      practice reference architecture, underpinned by our
                      methodology, functional expertise and our deep technical
                      knowledge in leading RPA technologies and tools. This
                      enables us to deliver the best outcome to address your
                      unique business problem through automation. We can help
                      you automate all your repetitive processes with full
                      visibility of operational performance. We can also augment
                      these processes with Artificial Intelligence (AI) and
                      Machine Learning (ML) models to deliver a tailored
                      solution.
                    </p>

                    <p>
                      Our <b>standard automation</b> offering can be used to
                      easily automate a range of repetitive processes from
                      analysing spreadsheets, summarising information, through
                      to managing email communication, weekly reporting and
                      customer onboarding.
                    </p>

                    <p>
                      Our <b>intelligent automation</b> offering is powered by
                      cutting edge AI & Machine Learning technology allowing us
                      to extract the data you need from any document and present
                      it in any format. Our{" "}
                      <b>Intelligent Document Processing</b> service has been
                      adopted by legal firms, banking institutions, insurance
                      companies, clinical practices, and knowledge management
                      teams to extract data from a variety of document types
                      viz. contracts, tender documents, legal agreements, RFP
                      documents, invoices.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div key="power-platform">
              <section className="container block p-md-3 p-lg-4 pt-0">
                <div className="row">
                  <div className="col-md-3 left pt-4">
                    <h3>We offer</h3>
                    <ul>
                      <li>Power apps</li>
                      <li>Power automate</li>
                      <li>Power BI</li>
                      <li>Power Virtual Agents</li>
                      <li>Microsoft workplace of the future</li>
                    </ul>
                  </div>
                  <div className="col-md-8 right" id="ms-power-platform">
                    <h3>Microsoft Power Platform CoE</h3>

                    <p>
                      Our <b>Power Platform CoE</b> enables organisations to
                      leverage the capabilities of Power BI, Power Apps, Power
                      Automate (RPA and Robotics) and Power Virtual Agents in
                      one environment.
                    </p>

                    <p>
                      We use <b>PowerBI and Power Platform</b> to develop
                      tailored solutions to validate and visualise your data,
                      allowing you to enhance the understanding and movement of
                      data in your organisation and provide more accurate
                      reporting that drives your better business decisions.
                    </p>

                    <p>
                      We have deep expertise in automating processes using
                      industry leading RPA technologies including{" "}
                      <b>Power Automate</b>. We use <b>Power Virtual Agents</b>{" "}
                      to enhance the customer experience (CX) at first point of
                      call for your clients and teams.
                    </p>
                    <p>
                      <b>Microsoft Workplace of the Future</b> - We build
                      Microsoft Modern Workplace solutions to improve employee
                      productivity and satisfaction, while maintaining the
                      security and integrity of systems and data in your
                      organisation. We have experience in integrating the Power
                      application suite including dashboards using Microsoft
                      Teams as a single pane of interaction. All your data and
                      all your approval workflows can also be accessed from
                      Microsoft Teams.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div key="ux">
              <section className="container block p-md-3 p-lg-4 pt-0">
                <div className="row">
                  <div className="col-md-3 left pt-4">
                    <h3>We offer</h3>
                    <ul>
                      <li>User centric design</li>
                      <li>Wireframing and prototypes</li>
                      <li>Experience design</li>
                      <li> User journeys and personas</li>
                      <li>Design thinking</li>
                    </ul>
                  </div>
                  <div className="col-md-8 right" id="ui-ux">
                    <h3>UX + Design</h3>

                    <p>
                      We can rapidly convert your ideas into something tangible
                      by developing both low fidelity and high fidelity
                      wireframes and prototypes. This step in the process can be
                      invaluable in reducing the product development effort,
                      quality and outcome considerably. 
                    </p>

                    <p>
                      Investing in building these wireframes and prototypes in
                      advance of application development, both you and your
                      development teams will always be on the same page by being
                      able to simultaneously visualise the end result and avoid
                      wastage due to ambiguous and misinterpreted expectations. 
                    </p>

                    <p>
                      Our UI/UX experts are well versed in user centric design
                      of both web and mobile applications. By using persona
                      based user journeys we can help you visualise your
                      application / product functionality, thereby improving the
                      overall ergonomics of the application usage. Our design
                      engineers are skilled in the Adobe XD and other popular
                      wireframing tools like Figma. We help organisations build
                      applications consistent with your brand identity and
                      colour schemes, so that your applications are high impact
                      and immediately stand out from the competition.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div key="cloud">
              {" "}
              <section className="container block p-md-3 p-lg-4 pt-0">
                <div className="row">
                  <div className="col-md-3 left pt-4">
                    <h3>We offer</h3>
                    <ul>
                      <li> Cloud Security Operations Advisory</li>
                      <li> Security Process Automation</li>
                      <li> Application Code Review</li>
                      <li> Cloud Security Assessments</li>
                      <li> Security Operations Centre (SOC)</li>
                    </ul>
                  </div>
                  <div className="col-md-8 right" id="cloud-security">
                    <h3>Cloud Security CoE</h3>
                    <p>
                      We partner with you to provide a comprehensive suite of
                      cybersecurity and risk assurance services to detect,
                      prevent, and proactively respond to security threats,
                      helping you to rapidly adapt to changes and manage risk.
                    </p>
                    <p>
                      We also provide application code review services,
                      vulnerability assessment including architecture reviews of
                      your cloud solutions and infrastructure. Our vCISO as a
                      Service offering can be leveraged to help you define your
                      cloud Information Security Management System (ISMS) -
                      security strategy, policies, procedures and governance
                      framework.
                    </p>

                    <p>
                      Our Security Process Automation offering <b>(PARs Bot)</b>{" "}
                      is an innovative autonomous automation solution to
                      automate the access control review process using
                      automation and AI. It saves a massive amount of manual
                      effort required from the security personnel to carry out
                      the process. The solution is expertly developed to
                      effectively ensure the periodic review of the user
                      privileges of all employees happens without delay and also
                      enabling greater accuracy. Deploying <b>PARs Bot</b> in
                      your organization can help you drastically reduce the
                      possibility of potentially dangerous security breaches.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </Tabs>
        </div>
      </section>
    </section>
  );
}

export default Services;
