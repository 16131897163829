import React from "react";
import "./App.scss";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ContactMap from "./components/contact-map/ContactMap";
import Layout from "./components/home-layout/Layout";
import About from "./components/about/About";
import Services from "./components/services/Services";
import Technologies from "./components/technologies/Technologies";
import Resources from "./components/resources/Resources";
import Privacy from "./components/privacy/Privacy";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ScrollToTop from "./hooks/ScrollToTop";

function App() {
  return (
    <div className="container p-0 top-border">
      <Router>
        <ScrollToTop />
        <Route path="/" exact component={Layout} />
        <Route path="/home" exact component={Layout} />
        <Route path="/services/:services" exact component={Services} />
        <Route path="/technologies" exact component={Technologies} />
        <Route path="/resources" exact component={Resources} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={ContactMap} />
        <Route path="/privacy" exact component={Privacy} />

        <Contact />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="gdprCookie"
          style={{ background: "#313c54" }}
          buttonStyle={{
            color: "#fff",
            fontSize: "13px",
            backgroundColor: "#ed4bb6",
          }}
          expires={150}
        >
          We use cookies to improve user experience and analyse website traffic.
          By clicking “Accept“, you agree to our website's cookie use as
          described in our privacy statement.
        </CookieConsent>
      </Router>
    </div>
  );
}

export default App;
