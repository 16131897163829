import "./Contact.scss";
import { Link, Redirect } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import useOnScreen from "../../hooks/hooks";
import { final, initial } from "../../hooks/AnimStyles";

function Contact() {
  function contactUs() {
    return <Redirect to="/contact" />;
  }

  const [animStyle, setAnimStyle] = useState(initial);

  const myRef: any = useRef();
  const isVisible = useOnScreen(myRef);

  useEffect(() => {
    if (isVisible) {
      setAnimStyle(final);
    }
  }, [isVisible]);

  return (
    <section>
      <div className="container contact p-lg-5">
        <motion.h1
          ref={myRef}
          animate={animStyle}
          className="text-center get-in-touch-heading"
        >
          Get in touch
        </motion.h1>
        <motion.h5
          ref={myRef}
          animate={animStyle}
          className="text-center get-in-touch"
        >
          <div className="row justify-content-center">
            <div className="col-lg-6 col-12 text-center justify-content-center">
              Have a project in mind? Let's talk. We'd love to hear from you.
              Here's how you can reach us...
            </div>
          </div>
        </motion.h5>

        <div className="container">
          <Link className="contact-button" to="/contact">
            Connect with us
          </Link>
          <div className="contact-number">
            <a href="tel:0800884332">0800884332</a>
          </div>
          
        </div>
        {/* <div className="phone-number">
          <a href="tel:0800884332">0800884332</a>
        </div> */}
      </div>
    </section>
  );
}

export default Contact;
