import "./Offer.scss";
import bt from "../../assets/images/business_transform.png";
import cloud from "../../assets/images/cloud.png";
import hyper_automation from "../../assets/images/hyper_automation.png";
import microsoft from "../../assets/images/microsoft.jpg";
import ux from "../../assets/images/ux.png";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { final, initial } from "../../hooks/AnimStyles";
import useOnScreen from "../../hooks/hooks";
import RellaxWrapper from "react-rellax-wrapper";

function Offer() {
  const [animStyle, setAnimStyle] = useState(initial);

  const myRef: any = useRef();
  const isVisible = useOnScreen(myRef);

  useEffect(() => {
    if (isVisible) {
      setAnimStyle(final);
    }
  }, [isVisible]);
  return (
    <section className="offer-container">
      <div className="container offer px-2 px-lg-4 py-5">
        <motion.h6 ref={myRef} animate={animStyle}>
          WHAT WE OFFER
        </motion.h6>
        <motion.h2 ref={myRef} animate={animStyle}>
          <div className="col-lg-6 col-12">
            We offer our clients simple, intuitive & scalable business solutions
            built on modern and secure technology platforms
          </div>
        </motion.h2>
      </div>

      <div className="container" style={{ background: "#f3efff" }}>
        <div
          className="container accord-conttainer p-lg-3 py-4 pt-1"
          style={{ position: "relative", background: "white", top: "-66px" }}
        >
          <h3 className="p-2 m-0 home-sub-title">OUR SERVICES</h3>

          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Business Transformation</Accordion.Header>
              <Accordion.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      Our Business Transformation practice is powered by data
                      driven intelligence, decades of real-world industry and
                      consulting experience supported by deep business insights
                      and analytics. We help organisations transform the way
                      they work through proven industry leading practices,
                      methods and tools which helps create improve productivity,
                      competitive advantage and shareholder value.
                    </div>
                    <div className="col-lg-4">
                      <img src={bt} className="service-image" />
                    </div>
                  </div>
                </div>

                <div className="ba-button">
                  <Link to="/services/business"> View Service Details</Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header> Hyper Automation</Accordion.Header>
              <Accordion.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      Our ‘Hyper Automation’ Centre of Excellence (CoE) combines
                      the power of Automation, Artificial Intelligence (AI) and
                      Analytics to extend the capabilities of Robotic Process
                      Automation (RPA) tools. We use a combination of AI based
                      automation to tackle complex business processes, ML to
                      identify and develop relevant data patterns and advanced
                      analytics to help you make good data-driven decisions in
                      real-time.
                    </div>
                    <div className="col-lg-4">
                      <img src={hyper_automation} className="service-image" />
                    </div>
                  </div>
                </div>

                <div className="ba-button">
                  <Link to="/services/robotic-process-automation">
                    View Service Details
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>MS Power Platform</Accordion.Header>
              <Accordion.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      Along with our deep software engineering expertise and our{" "}
                      <b>Microsoft Gold Partner Certified CoE</b>, we offer a
                      turnkey <b>Microsoft Workplace of the Future</b> solution
                      which encompasses designing, building and supporting of
                      your entire Microsoft ecosystem. We offer advisory,
                      strategy, design, build, support and review services of
                      Power Platform based point solutions or a best practice
                      blueprint to improve the adoption and return on investment
                      of your Microsoft 365 subscription.
                    </div>
                    <div className="col-lg-4">
                      <img src={microsoft} className="service-image" />
                    </div>
                  </div>
                </div>

                <div className="ba-button">
                  <Link to="/services/ms-power-platform">
                    View Service Details
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header style={{ color: "pink" }}>
                UX + Design
              </Accordion.Header>
              <Accordion.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      Based on human centric design frameworks, we rapidly
                      design, develop and refine high-impact creative solutions
                      - bringing together ideas and teams from across
                      disciplines to create the experiences users wish for.
                    </div>
                    <div className="col-lg-4">
                      <img src={ux} className="service-image" />
                    </div>
                  </div>
                </div>

                <div className="ba-button">
                  <Link to="/services/ui-ux">View Service Details</Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Cloud Security</Accordion.Header>
              <Accordion.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      The new norm of remote working driven by advancements in
                      technology and a global health crisis has changed the
                      cyber threat landscape entirely. Today, more businesses
                      are adopting Cloud and SaaS applications, making them more
                      vulnerable to attacks. Our experience in developing and
                      deploying applications into the cloud can be leveraged to
                      help protect your digital assets and data.
                    </div>
                    <div className="col-lg-4">
                      <img src={cloud} className="service-image" />
                    </div>
                  </div>
                </div>

                <div className="ba-button">
                  <Link to="/services/cloud-security">
                    {" "}
                    View Service Details
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="accordian-height">&nbsp;</div>
        </div>
      </div>
    </section>
  );
}
export default Offer;
