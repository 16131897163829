import "./Footer.scss";
import LogoFooter from "../../assets/images/logo_footer.png";
import TwitterLogo from "../../assets/images/Twitter_white.png";
import YoutubeLogo from "../../assets/images/Youtube_white.png";
import LinkedinLogo from "../../assets/images/LinkedIN_white.png";
import FacebookLogo from "../../assets/images/Facebook_white.png";
import InstaLogo from "../../assets/images/Instagram_white.png";
import { Link } from "react-router-dom";
import RellaxWrapper from "react-rellax-wrapper";

function Footer() {
  return (
    <section>
      <footer className="container px-lg-4 footer">
        <div className="row">
          <div className="col-12 col-lg-4 block-1">
            <img className="footer-logo" alt="Gravitus" src={LogoFooter} />
            <div className="social">
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100058913267323"
              >
                <img src={FacebookLogo} alt="Facebook" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/gravitus-digital/"
              >
                <img src={LinkedinLogo} alt="LinkedIn" />
              </a>
              <a target="_blank" href="https://twitter.com/GravitusDigital">
                <img src={TwitterLogo} alt="Twitter" />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCHgjUfX97qNzGqeoep2UOGQ"
              >
                <img src={YoutubeLogo} alt="Youtube" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/gravitusdigital/"
              >
                <img src={InstaLogo} alt="Instagram" />
              </a>
              <div className="copyright">
                Copyright &copy; gravitus.co.nz 2021
                <div className="design-developed">
                  Designed and developed by Gravitus Digital
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="footer-block">
              <h4>ABOUT US</h4>
              <ul>
                <li>
                  <Link to="/about">Our Team</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Statement</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="footer-block">
              <h4>SERVICES</h4>
              <ul>
                <li>
                  <Link to="/services/business">Business Transformation</Link>
                </li>
                <li>
                  <Link to="/services/robotic-process-automation">
                    Hyper Automation
                  </Link>
                </li>
                <li>
                  <Link to="/services/ms-power-platform">
                    MS Power Platform
                  </Link>
                </li>
                <li>
                  <Link to="/services/ui-ux">UX + Design</Link>
                </li>
                <li>
                  <Link to="/services/cloud-security">Cloud Security</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="footer-block">
              <h4>CONTACT US</h4>
              <ul>
                <li>
                  <Link to="/contact">Connect with us</Link>
                  <div>
                    <a href="tel:0800884332">0800884332</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default Footer;
