import TopNav from "../topnav/TopNav";
import "./About.scss";
import PartnerImg from "../../assets/images/partner.png";
import PartnershipImg from "../../assets/images/partnership.png";
import { Carousel } from "react-bootstrap";
import { motion } from "framer-motion";
import RellaxWrapper from "react-rellax-wrapper";

import {
  containerVariants,
  headingVariants,
  subheadingVariants,
} from "../../hooks/AnimStyles";
function About() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
      },
    },
  };

  const listItem = {
    hidden: { opacity: 0, y: 25 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <section>
      <motion.section
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="about-container"
      >
        <TopNav />
        <div className="page-banner">
          <RellaxWrapper speed={5}>
            <motion.h1 variants={headingVariants}>
              Gravitate to Us to create digital excellence
            </motion.h1>

            <motion.h5 variants={subheadingVariants}>
              We are a team of innovators and pioneers, in the pursuit of
              excellence, to transform simple ideas into sensational success
              stories.
            </motion.h5>
          </RellaxWrapper>
        </div>
      </motion.section>
      <section className="about-content py-4 px-lg-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-5 blue-big-text">
              We do what we do, because we believe in the art of creating
              something truly unique, by combining human-centered design with
              business & technology.
            </div>
            <div className="col-lg-8 col-sm-7 ">
              <div className="text-align-left">
                <h3 className="text-align-left">Why Gravitus - The purpose</h3>
              </div>

              <h5> Our Vision </h5>
              <p>
                Our vision is to be the most innovative future focused
                technology company in the Southern Hemisphere. We would like to
                see Aotearoa as one of the most digitised nations in the world.
                By this we don’t mean making human beings redundant, but in fact
                augmenting human potential with technology and reimagining the
                work place of the future. We envision a future where digital
                workers (bots) work alongside humans, helping organisations
                achieve higher levels of customer satisfaction, productivity.
              </p>

              <h5>Our Belief</h5>

              <p>
                We believe in changing the lives of humans through innovative
                digital solutions. We know that change is hard and that it is
                not uncommon for businesses to get overwhelmed by the task ahead
                - We know this because we have been there ourselves. We know
                what it's like to fail but we also know what it feels like to
                guide an organisation through change and get to the other side
                with great success. In these trying times of resource shortages
                and skills inadequacies, you need to a partner that has not only
                had their share of real-world human experiences and has the
                battle scars to prove it; but a partner that can scale with your
                business. A partner you can trust to always have your back when
                it matters the most. We believe we can be that partner!
              </p>
            </div>
          </div>
        </div>

        <div className="container advantage mt-4 mobile-only">
          <div className="row">
            <div className="col">
              <h3>How we realise our purpose – The process</h3>
              <div>
                <Carousel>
                  <Carousel.Item>
                    <div className="col-lg-3 col-md-5 bottom-block block-1">
                      <h4>Our Team</h4>
                      <ul className="advantages">
                        <li>150+ Certified Professionals</li>
                        <li>Global set of employees</li>
                        <li>Local representative for orchestration</li>
                        <li>16 hour work day as opposed to 8 hours</li>
                        <li>
                          Global time difference yields increased time for
                          productivity
                        </li>
                        <li>“We work even while you sleep”</li>
                        <li>24/7 Managed Service Desk</li>
                      </ul>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="col-lg-3 col-md-5  bottom-block block-2">
                      <h4>Our Methodology</h4>
                      <ul className="advantages">
                        <li>Hybrid Agile Scrum Approach</li>
                        <li>Tried & Tested</li>
                        <li>
                          Owner of process has all round access to outcome
                          delivery process
                        </li>
                        <li>
                          Our Methodology allows for faster delivery of value to
                          client
                        </li>
                      </ul>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="col-lg-3 col-md-5  bottom-block block-3">
                      <h4>Our Skills and Tools</h4>
                      <ul className="advantages">
                        <li>300+ Person years of executive experience</li>
                        <li>Highly Skilled</li>
                        <li>Highly Experienced</li>
                        <li>Access to large variety of emerging tech tools</li>
                        <li>Applying tech in business contexts</li>
                        <li>Big 4 Consulting Experience</li>
                      </ul>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="col-lg-3 col-md-5 bottom-block block-4">
                      <h4>Our Global Experience</h4>
                      <ul className="advantages">
                        <li>Banking & Finance</li>
                        <li>Insurance</li>
                        <li>Healthcare</li>
                        <li>Logistics</li>
                        <li>Manufacturing</li>
                        <li>Telecom</li>
                        <li>Food & Beverage</li>
                        <li>Retail</li>
                        <li>Oil & Gas</li>
                        <li>Shared Services</li>
                      </ul>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col12 col-lg-4"></div>
            <div className="col12 col-lg-8">
              <h3 className="the-process-heading desktop-only">
                How we realise our purpose – The process
              </h3>
            </div>
          </div>
        </div>

        <div className="container advantage mt-4 desktop-only">
          <motion.div
            className="row bottom-block-row"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <motion.div
              variants={listItem}
              className="col-lg-5 col-xl-3 bottom-block block-2"
            >
              <h4>Our Team</h4>
              <ul className="advantages">
                <li>150+ Certified Professionals</li>
                <li>Global set of employees</li>
                <li>Local representative for orchestration</li>
                <li>16 hour work day as opposed to 8 hours</li>
                <li>
                  Global time difference yields increased time for productivity
                </li>
                <li>“We work even while you sleep”</li>
                <li>24/7 Managed Service Desk</li>
              </ul>
            </motion.div>
            <motion.div
              className="col-lg-5 col-xl-3 bottom-block block-3"
              variants={listItem}
            >
              <h4>Our Methodology</h4>
              <ul className="advantages">
                <li>Hybrid Agile Scrum Approach</li>
                <li>Tried & Tested</li>
                <li>
                  Owner of process has all round access to outcome delivery
                  process
                </li>
                <li>
                  Our Methodology allows for faster delivery of value to client
                </li>
              </ul>
            </motion.div>
            <motion.div
              className="col-lg-5 col-xl-3 bottom-block block-1"
              variants={listItem}
            >
              <h4>Our Skills and Tools</h4>
              <ul className="advantages">
                <li>300+ Person years of executive experience</li>
                <li>Highly Skilled</li>
                <li>Highly Experienced</li>
                <li>Access to large variety of emerging tech tools</li>
                <li>Applying tech in business contexts</li>
                <li>Big 4 Consulting Experience</li>
              </ul>
            </motion.div>
            <motion.div
              className="col-lg-5 col-xl-3 bottom-block block-4"
              variants={listItem}
            >
              <h4>Our Global Experience</h4>
              <ul className="advantages">
                <li>Banking & Finance</li>
                <li>Insurance</li>
                <li>Healthcare</li>
                <li>Logistics</li>
                <li>Manufacturing</li>
                <li>Telecom</li>
                <li>Food & Beverage</li>
                <li>Retail</li>
                <li>Oil & Gas</li>
                <li>Shared Services</li>
              </ul>
            </motion.div>
          </motion.div>
        </div>

        <div className="container py-4">
          <div className="row">
            <div className="col">
              <h3>Partners</h3>
              <div className="pt-3 pb-4 partner-img-container">
                <img className="img-fluid" alt="Partner" src={PartnerImg} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col pb-4">
              <img
                className="img-fluid"
                alt="Partnership"
                src={PartnershipImg}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4">
              <p className="blue-big-text">Why did we partner with 10xDS?</p>
            </div>
            <div className="col-12 col-lg-8">
              <p>
                We partnered with 10xds because they had a similar vision to us.
                Our objective was to collaborate with a partner that had the
                expertise, maturity and global coverage to deliver a world class
                experience to our clients. A partner was big enough to be able
                to easily scale to meet the growing demands of the New Zealand
                market and be able to pivot quickly enough to respond to the
                rapidly changing business ecosystem. We wanted the rigor of a
                mature established organisation with the agility and growth
                mindset of a startup. 10xDS ticked all those boxes for us.
              </p>
              <p>
                This partnership is not just about skills, capacity and cost but
                more about bringing a pioneering spirit and thought leadership
                from the best minds in the business to the New Zealand shores.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4"></div>
            <div className="col-12 col-lg-8">
              <h3 style={{ textAlign: "left" }}>What we offer – The Result</h3>
              <p>
                We stand apart from the crowd with our deep expertise in niche
                and emerging technologies. We infuse humanities into our
                technology solutions using a human-centric design approach. We
                don’t just develop software we design and build intuitive
                digital solutions which solve complex business and are easy to
                use.
              </p>
            </div>
          </div>

          <div className="mobile-only">
            <Carousel>
              <Carousel.Item>
                <div className="col-12 col-lg-4 p-3 block">
                  <h3>Hyper Automation</h3>
                  <p>
                    Our ‘Hyper Automation’ solution combines the power of AI and
                    machine learning (ML) to extend the capabilities of RPA
                    tools. We use AI to make agile decisions to handle your
                    complex business processes and ML to identify data patterns.
                    Combined power of AI , ML and RPA bots enables the solution
                    to make realtime decisions based on data.
                  </p>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="col-12 col-lg-4 p-3 block">
                  <h3>MS Power Platform CoE</h3>
                  <p>
                    Along with our deep software engineering expertise and our
                    Microsoft Gold Partner certified CoE 10xds, we have the
                    ability to offer turnkey solutions which cover designing,
                    building and supporting your Microsoft ecosystem. We can
                    come in, consult, implement, support and review your
                    Microsoft 365 suite.
                  </p>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="col-12 col-lg-4 p-3 block">
                  <h3>UX + Design</h3>
                  <p>
                    Based on human centric design frameworks, we rapidly design,
                    develop and refine high-impact creative solutions - bringing
                    together ideas and teams from across disciplines to create
                    the experiences users wish for.
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>

          <div className="row desktop-only">
            <div className="col-12 col-lg-4 p-3 block">
              <h3>Hyper Automation</h3>

              <p>
                Our ‘Hyper Automation’ solution combines the power of AI and
                machine learning (ML) to extend the capabilities of RPA tools.
                We use AI to make agile decisions to handle your complex
                business processes and ML to identify data patterns. Combined
                power of AI , ML and RPA bots enables the solution to make
                realtime decisions based on data.
              </p>
            </div>
            <div className="col-12 col-lg-4 p-3 block">
              <h3>MS Power Platform CoE</h3>
              <p>
                Along with our deep software engineering expertise and our
                Microsoft Gold Partner certified CoE 10xds, we have the ability
                to offer turnkey solutions which cover designing, building and
                supporting your Microsoft ecosystem. We can come in, consult,
                implement, support and review your Microsoft 365 suite.
              </p>
            </div>
            <div className="col-12 col-lg-4 p-3 block">
              <h3>UX + Design</h3>
              <p>
                Based on human centric design frameworks, we rapidly design,
                develop and refine high-impact creative solutions - bringing
                together ideas and teams from across disciplines to create the
                experiences users wish for.
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default About;
