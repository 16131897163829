import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/gravitus logo.png";
// import Talk from "../../assets/images/talk.png";
import useScrollBlock from "../../hooks/useScrollBlock";
import "./TopNav.scss";

function TopNav() {
  let pathname = window.location.pathname;

  const [blockScroll, allowScroll] = useScrollBlock();

  const [flyoutmenu, setFlyoutMenu] = useState("");

  useEffect(() => {
    // pathname = window.location.pathname;
    return () => {
      allowScroll();
    };
  }, []);

  const toggleNavMenu = () => {
    const menustate = flyoutmenu === "" ? "open" : "";
    if (menustate === "open") {
      blockScroll();
    } else {
      allowScroll();
    }

    setFlyoutMenu(menustate);
  };

  return (
    <nav className="navbar navbar-light navbar-expand-lg px-lg-4 px-2 pt-2 pt-lg-4 top-fixed">
      <div className="container-lg p-0">
        <Link to="/">
          <img src={Logo} alt="Gravitus" className="logo " />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#nav-links"
          aria-controls="nav-links"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNavMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`offcanvas-collapse navbar-collapse justify-content-end ${flyoutmenu}`}
          id="nav-links"
        >
          <ul className="navbar-nav justify-content-between align-items-center w-100">
            <li className="nav-item">
              <Link
                className={`${pathname.match("/home") ? "link-active" : ""}`}
                to="/home"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  pathname.match("/business") ? "link-active" : ""
                }`}
                to="/services/business"
              >
                {/* <a className="nav-link p-0" aria-current="page" href="index.html"> */}
                Services
                {/* </a> */}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  pathname.match("/technologies") ? "link-active" : ""
                }`}
                to="/technologies"
              >
                {/* <a className="nav-link p-0" href="index.html"> */}
                Technologies
                {/* </a> */}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  pathname.match("/resources") ? "link-active" : ""
                }`}
                to="/resources"
              >
                {/* <a className="nav-link p-0" href="index.html"> */}
                Industries
                {/* </a> */}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${pathname.match("/about") ? "link-active" : ""}`}
                to="/about"
              >
                {/* <a className="nav-link p-0" href="index.html"> */}
                Why Gravitus
                {/* </a> */}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className={`${pathname.match("/contact") ? "link-active" : ""}`}
              >
                {/* <a className="nav-link p-0" href="index.html"> */}
                Contact
                {/* </a> */}
              </Link>
            </li>
            <li className="nav-item">
             <Link to={{ pathname: "https://blog.gravitus.co.nz" }} target="_blank" >
                {/* <a className="nav-link p-0" href="index.html"> */}
                Blog
                {/* </a> */}
              </Link>
            </li>
            <li className="nav-item justify-content-center text-center">
              {/* <a className="nav-link p-0" href="index.html"> */}
              {/* <img src={Talk} alt="Talk" className="talk" /> */}
              {/* </a> */}
            </li>
            {/* <li className="nav-item justify-content-center text-center">
              <img src={Talk} alt="Talk" className="talk" />
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default TopNav;
