import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import RellaxWrapper from "react-rellax-wrapper";
import {
  containerVariants,
  headingVariants,
  subheadingVariants,
} from "../../hooks/AnimStyles";
import TopNav from "../topnav/TopNav";
import "./Technologies.scss";

function Technologies() {
  
  return (
    <section>

      <motion.section
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="technologies-container"
      >
        <TopNav />

        <div className="page-banner">
        <RellaxWrapper speed={5}>

        <motion.h1 variants={headingVariants}>
            Modern web, hyper automation and public cloud
          </motion.h1>

          <motion.h5 variants={subheadingVariants}>
            We help our clients use web , mobile and hyper automation
            technologies to transform their businesses
          </motion.h5>

        </RellaxWrapper>
        </div>
       

      </motion.section>
    
      <section className="technologies-content">
        <div className="container p-5">
          <div className="row">
            <div className="col-12 col-lg-4 left-block">
              <h3>Modern Web Technologies</h3>
              <p>
                We are across all modern web frameworks out there to bring in
                the slick interface you need
              </p>
            </div>
            <div className="col">
              <div className="container tech-grid grid-1">
                <div className="row">
                  <div className="col icon icon-1"></div>
                  <div className="col icon icon-2"></div>
                  <div className="col icon icon-3"></div>
                </div>
                <div className="row separator">
                  <div className="col icon icon-4"></div>
                  <div className="col icon icon-5"></div>
                  <div className="col icon icon-6"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-separator">
            <div className="col-12 col-lg-4 left-block">
             
              <h3>Business Layer and Automation</h3>
              <p>
                We are agile enough to use a variety of frameworks and
                automation products to onboard your business rules and processes
              </p>
            </div>
            <div className="col">
              <div className="container tech-grid grid-2">
                <div className="row">
                  <div className="col icon icon-1"></div>
                  <div className="col icon icon-2"></div>
                  <div className="col icon icon-3"></div>
                </div>
                <div className="row separator">
                  <div className="col icon icon-4"></div>
                  <div className="col icon icon-5"></div>
                  <div className="col icon icon-6"></div>
                </div>
                <div className="row separator">
                  <div className="col icon icon-7"></div>
                  <div className="col icon icon-8"></div>
                  <div className="col icon icon-9"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-separator">
            <div className="col-12 col-lg-4 left-block">
              <h3>Cloud Platforms</h3>
              <p>
                We support all major cloud platforms and hybrid environments
              </p>
            </div>
            <div className="col">
              <div className="container tech-grid grid-3">
                <div className="row">
                  <div className="col icon icon-1"></div>
                  <div className="col icon icon-2"></div>
                  <div className="col icon icon-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </section>

  );
}

export default Technologies;
