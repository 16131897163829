import firebase from "firebase/app";
import "firebase/firestore";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdkGYo7EI-4je-CtuCxH2yPxLtvG3k6ns",
  authDomain: "gravitusdigitalweb.firebaseapp.com",
  projectId: "gravitusdigitalweb",
  storageBucket: "gravitusdigitalweb.appspot.com",
  messagingSenderId: "382150884016",
  appId: "1:382150884016:web:26fab819055e12be4cd975",
  measurementId: "G-YXJDW08G5W",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
