import TopNav from "../topnav/TopNav";
import "./ContactMap.scss";
import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import { Modal, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import {
  containerVariants,
  headingVariants,
  subheadingVariants,
} from "../../hooks/AnimStyles";
import RellaxWrapper from "react-rellax-wrapper";
import { useLocation } from "react-router-dom";

function Contact() {
  const [scrollTime, setScrollTime] = useState(0);

  useEffect(() => {
    if (scrollTime == 0) {
      window.scrollTo(0, 0);
      setScrollTime(1);
    }
  });

  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
    name: "",
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  async function submitForm(event: any) {
    if (
      formData.email === "" ||
      formData.name === "" ||
      formData.subject === ""
    ) {
      return;
    }
    event.preventDefault();
    let db = firebase.firestore();
    db.collection("contact")
      .add({
        email: formData.email,
        name: formData.name,
        message: formData.message,
        subject: formData.subject,
      })
      .then(() => {
        setShow(true);
      })
      .catch((err: any) => {
        console.log(JSON.stringify(err));
        alert("Sorry we couldn't talk with our server.");
      });
  }
  return (
    <section className="contact-us">
      <motion.section
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="contact-container"
      >
        <TopNav />
        <div className="page-banner">
          <RellaxWrapper speed={5}>
            <motion.h1 variants={headingVariants}>
              Let’s create progress together
            </motion.h1>

            <motion.h5 variants={subheadingVariants}>Have a project in mind? Let's talk. We'd love to hear from you.</motion.h5>
          </RellaxWrapper>
        </div>
      </motion.section>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 col-md-6 col-sm-12 google-map">
            {/* <div> <div>
              New Zealand
            </div>
              <div>
              <div>Suite 12291</div>
              <div>Level 1, 6 Johnsonville Road</div>
              <div>Johnsonville</div>
              <div>Wellington 6037</div>
              <div>New Zealand</div>
              </div></div> */}

            <div className="map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.6334936191765!2d174.77985011501806!3d-36.851252779938086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d480f91933359%3A0x925dc4e3e2e6adba!2s17b%20Farnham%20Street%2C%20Parnell%2C%20Auckland%201052%2C%20New%20Zealand!5e0!3m2!1sen!2sin!4v1631821585520!5m2!1sen!2sin"
                width="100%"
                height="450"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <div>
                <div className="section-title">
                  <br></br>
                  <br></br>
                  <h2 className="text-primary">Write to us</h2>
                  <p>For all enquiries please email us, using the form below</p>
                </div>
              </div>
            </div>
            <form action="/" className="mb-4 mb-lg-0">
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    required
                    className="form-control"
                    id="name"
                    placeholder="Name*"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                    name="email"
                    id="email"
                    placeholder="Email*"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  required
                  placeholder="Subject"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <textarea
                  rows={5}
                  className="form-control"
                  name="message"
                  id="message"
                  placeholder="Message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-light"
                onClick={submitForm}
              >
                Send Now
              </button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>We will contact you soon.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Contact;
