export const initial = { opacity: 0, y: 25 };
export const final = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.45,
    delay: 0.1,
  },
};

export const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
    },
  },
};

export const headingVariants = {
  hidden: { y: 25, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.5,
    },
  },
};
export const subheadingVariants = {
  hidden: { y: 25, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      delay: 0.25,
      bounce: 0.5,
    },
  },
};
