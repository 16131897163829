import Approach from "../approach/Approach";
import Home from "../home/Home";
import Offer from "../offer/Offer";
import Team from "../team/Team";

function Layout() {
  return (
    <div>
      <Home />
      <Approach />
      <Offer />
      <Team />
    </div>
  );
}

export default Layout;
