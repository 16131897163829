import "./Home.scss";
import HomeBg1 from "../../assets/images/home-bg1.png";
import HomeBg2 from "../../assets/images/home-bg2.png";
import HomeBg4 from "../../assets/images/home-bg4.png";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import TopNav from "../topnav/TopNav";
import { motion } from "framer-motion";
import { useState } from "react";
import {
  containerVariants,
  headingVariants,
  subheadingVariants,
} from "../../hooks/AnimStyles";
import RellaxWrapper from "react-rellax-wrapper";

const Slideshow = () => {
  const [animateTwo, setAnimateTwo] = useState(headingVariants.hidden);
  const [animateThree, setAnimateThree] = useState(headingVariants.hidden);

  const properties = {
    infinite: true,
    indicators: true,
    autoplay: true,
    duration: 5000,
    transitionDuration: 800,
    onChange: (e: any, i: any) => {
      if (i === 1) {
        setAnimateTwo(headingVariants.visible);
      } else if (i === 2) {
        setAnimateThree(headingVariants.visible);
      }
    },
    prevArrow: (
      <div style={{ width: "0px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div style={{ width: "0px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </div>
    ),
  };

  return (
    <Slide {...properties}>
      <div className="px-2 px-lg-4 each-fade container-lg dis-flex flex-column justify-content-center home-container">
        <img src={HomeBg2} alt="Gravitus" className="img-fluid d-flex" />
        <div className="slider-headings">
          <RellaxWrapper speed={5}>
            <motion.h1 variants={headingVariants} className="text-left">
              He Timatanga Hou
            </motion.h1>
            <motion.h4 variants={subheadingVariants} className="text-left">
              A New Beginning starts with a simple idea and a desire to change.
            </motion.h4>
          </RellaxWrapper>
        </div>
      </div>
      <div className="px-2 px-lg-4 each-fade container-lg dis-flex flex-column justify-content-center home-container">
        <img src={HomeBg1} alt="Gravitus" className="img-fluid d-flex oculus" />
        <div className="slider-headings">
          <RellaxWrapper speed={5}>
            <motion.h1 animate={animateTwo} className="text-left">
              Simple future driven solutions
            </motion.h1>
            <motion.h4 animate={animateTwo} className="text-left">
              We empower our clients to bring their vision to life through
              creative future focused digital solutions.
            </motion.h4>
          </RellaxWrapper>
        </div>
      </div>
      <div className="px-2 px-lg-4 each-fade container-lg dis-flex flex-column justify-content-center home-container">
        <img src={HomeBg4} alt="Gravitus" className="img-fluid d-flex" />
        <div className="slider-headings">
          <RellaxWrapper speed={5}>
            <motion.h1 animate={animateThree} className="text-left">
              Your New Zealand partner for innovative human-centric digital
              solutions
            </motion.h1>
            <motion.h4 animate={animateThree} className="text-left">
              Our design thinking starts with blending the humanities with the
              tech.
            </motion.h4>
          </RellaxWrapper>
        </div>
      </div>
    </Slide>
  );
};

function Home() {
  function explore() {
    var scrollDiv: any = window.document.getElementById("our-approach");
    scrollDiv = scrollDiv.offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
  }

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="home-wrapper"
    >
      <TopNav />
      <Slideshow />
      <div className="explore" onClick={() => explore()}></div>
    </motion.div>
  );
}

export default Home;
