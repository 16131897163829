import { motion } from "framer-motion";
import TopNav from "../topnav/TopNav";
import {
  containerVariants,
  headingVariants,
  subheadingVariants,
} from "../../hooks/AnimStyles";

import "./Privacy.scss";
function Privacy() {
  // window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <section>
      <motion.section
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="privacy-container"
      >
        <TopNav />
        <motion.h1 variants={headingVariants}>Our Privacy Policy</motion.h1>
      </motion.section>
      <section className="privacy-content py-4 px-lg-4">
        <div className="container">
          <p>
            This Privacy Policy governs the manner in which Gravitus Limited
            collects, uses, stores, maintains and discloses information
            collected from users (each, a "User") of the gravitus.co.nz website
            or other Gravitus digital channels (such as Facebook or LinkedIn).
          </p>

          <b>Personal identification information</b>

          <p>
            We may collect personal information from Users in a variety of ways,
            including, but not limited to, when Users visit our Website,
            register on the Website, fill out a form, and in connection with
            other activities, services, features or resources we make available
            on our Website or on other Gravitus digital channels. Users may be
            asked for, as appropriate, name, email address, phone number or
            other personal information. Users may also visit our Website
            anonymously. We will collect personal information from Users only if
            they voluntarily submit such information to us via the Website or
            via other Gravitus digital channels. Users can always refuse to
            supply personal information, but that may prevent them from engaging
            in certain activities related to the Website or other Gravitus
            digital channel.
          </p>

          <b>Non-personal identification information</b>
          <p>
            We may collect non-personal information about Users whenever they
            interact with our Website or other digital channels. Non-personal
            information may include the browser name, the type of computer and
            technical information about Users means of connection to our Website
            or other digital channels, such as the operating system and the
            Internet service providers utilised and other similar information.
          </p>

          <b>Web browser cookies</b>
          <p>
            Our Website and digital channels may use "cookies" to enhance Users
            experience. Users web browser places cookies on their hard drive for
            record-keeping purposes and sometimes to track information about
            them. Users may choose to set their web browser to refuse cookies,
            or to alert you when cookies are being sent. If they do so, some
            parts of the Website may not function properly.
          </p>

          <b>How we use collected information</b>
          <p>
            Gravitus may collect, store and use Users personal information for
            the following purposes: To run and operate our Website or platform
            on other digital channels For example, we may need your information
            to display content on the Website correctly. To personalize Users
            experience For example, we may use aggregated information to
            understand how our Users as a group use the services and resources
            provided on our Website or on our other digital channels. To improve
            our Website or platform on other digital channels For example, we
            may use feedback you provide to improve our products and services.
            To send periodic emails We may use your email address to send
            updates, offers or news. It may also be used to respond to a User’s
            enquiries, questions, and/or other requests.
          </p>

          <b>How we protect your information</b>
          <p>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorised
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Website or other digital channels.
          </p>

          <b>Sharing your personal information</b>
          <p>
            We do not sell, trade, or rent Users personal information to others.
            We may share generic aggregated demographic information not linked
            to any personal information regarding visitors and Users with our
            business partners, trusted affiliates and advertisers for the
            purposes outlined above. We may use third party service providers to
            help us operate our business and the Website or other digital
            channels or administer activities on our behalf, such as sending out
            newsletters or surveys. We may share your information with these
            third parties for those limited purposes.
          </p>

          <b>Email newsletters </b>
          <p>
            If Users decide to opt-in to our mailing list, they will receive
            emails that may include company news, updates, related product or
            service information, etc. We may use third party service providers
            to help us operate our business and the Website or other digital
            channels or administer activities on our behalf, such as sending out
            newsletters or surveys. We may share your information with these
            third parties for those purposes provided that you have given us
            your permission. Opt out or unsubscribe functions, from those
            specific lists, will always be provided within those emails.
          </p>

          <b>Third party websites</b>
          <p>
            Users may find advertising or other content on our Website that link
            to the sites and services of our partners, suppliers, advertisers,
            sponsors, licensors and other third parties. We do not control the
            content or links that appear on these sites and are not responsible
            for the content of or practices employed by websites linked to or
            from our Website. In addition, these sites or services, including
            their content and links, may be constantly changing. These sites and
            services may have their own privacy policies and customer service
            policies. Browsing and interaction on any other website, including
            websites which have a link to our Website, is subject to that
            website's own terms and policies. That also applies to the host or
            owner of other digital channels that Gravitus may use, such as
            Facebook or LinkedIn. Users will be subject to the terms and
            policies of those hosts and owners in connection with personal
            information provided via those digital channels.
          </p>

          <b>Google Analytics cookies</b>
          <p>
            Our Website uses Google Analytics to help analyse how visitors use
            our Website. Google Analytics uses “cookies”, which are small text
            files placed on your computer, to collect standard internet log
            information and visitor behaviour information in an anonymous form.
          </p>

          <p>
            The anonymous information generated by Google Analytics cookies
            about your use of our Website is transmitted to Google. This
            information is processed to compile statistical reports on website
            activity for this website only.
          </p>

          <b>Google Remarketing</b>
          <div>
            We may use Google AdWords Remarketing to advertise Gravitus across
            the Internet, in particular on the Google Display Network. AdWords
            remarketing may display ads to a User based on what parts of our
            Website you have viewed by placing a cookie on your web browser.
            This cookie does not in any way identify you individually or give
            access to your computer or mobile device. The cookie is used to
            indicate to other websites that “This User visited a particular
            page, so show them ads relating to that page.” Google AdWords
            Remarketing allows us to tailor our marketing to better suit your
            needs and only display ads that are relevant to you. If you do not
            wish to see ads from Gravitus you can opt out in several ways:
            <p>
              1.Opt out of Google’s use of cookies by visiting Google’s Ads
              Settings
            </p>
            <p>
              2. Opt out of a third-party vendor’s use of cookies by visiting
              the Network Advertising Initiative opt out page.
            </p>
          </div>

          <b>Changes to this privacy policy</b>
          <p>
            Gravitus has the discretion to update this privacy policy at any
            time. We encourage Users to frequently check this page for any
            changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of updates.
          </p>
        </div>
      </section>
    </section>
  );
}

export default Privacy;
